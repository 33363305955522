@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins&display=swap");

body {
  margin: 0;
  background-color: #fff2f2;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Pacifico", cursive;
}

.paywithpaypal {
  font-family: "Poppins", sans-serif;
}

.site-container {
  height: 100vh;
}

.colscroll {
  max-height: 300px;
  overflow-y: scroll;
}

.colscroll2 {
  max-height: 150px;
  overflow-y: scroll;
}

main {
  margin-top: 85px;
  flex: 1;
}

.category-button {
  border: none;
  border-radius: 10px;
  background-color: #fff2f2;
  padding: 3px 7px;
  margin-left: 10px;
}

.category-button:hover {
  background-color: #ef9f9f;
  color: #f1f1f1;
}

.homepage-button {
  border: none;
  border-radius: 10px;
  background-color: #fff;
  padding: 3px 7px;
}

.homepage-button a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.password-input {
  position: relative;
}

.eye-button {
  background: none;
  border: none;
  position: absolute;
  right: 2px;
  top: 8px;
}

.bell-button {
  background: none;
  border: none;
  font-size: 20px;
  color: black;
}

.leaflet-container {
  width: 100wh;
  height: 50vh;
}

.scroll {
  max-height: 43vh;
  overflow-y: scroll;
}

@media screen and (min-width: 768px) {
  .sticky {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 150px;
    align-self: flex-start; /* <-- this is the fix */
  }
}

/*INPUTS*/
.switch-price {
  margin-bottom: 0.17rem;
}
.switch-price-2 {
  margin-bottom: 6px;
}

/*BREADCRUMB*/

.modal-header {
  border-bottom: none;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}
.breadcrumb-item a:hover {
  border-bottom: solid 2px #000;
}

.breadcrumb-item {
  padding: 5px;
}

.is-sticky {
  position: fixed;
  top: 118px;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff2f2 !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.is-sticky-2 {
  position: fixed;
  top: 105px;
  width: 100%;
  z-index: 999;
  background-color: #fff2f2 !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

@media (max-width: 575px) {
  .is-sticky-2 {
    top: 126px;
    left: 0;
  }
}

/*IMAGES*/
.img-thumbnail {
  width: 80px;
  min-height: 80px;
  max-height: 80px;
  height: auto;
  object-fit: cover;
  border: none;
}

.img-vignette-product {
  width: 65px;
  height: 65px;
  object-fit: cover;
}

/* PAGINATION */
.pagination {
  --bs-pagination-active-bg: #ef9f9f;
  --bs-pagination-active-border-color: #f47c7c;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-color: #333;
  --bs-pagination-hover-color: #333;
  --bs-pagination-focus-color: #333;
}

/*MENUS*/
.list-group .activee {
  background-color: #ef9f9f;
}

.list-group .activee a {
  text-decoration: none;
  color: #f1f1f1;
}

.list-group a {
  text-decoration: none;
  font-weight: 600;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-product-link {
  font-weight: 600;
  color: #000;
  text-decoration: none;
}

.product-infos a {
  color: #f47c7c;
}

/*COULEURS GENERALES*/
.bg1 {
  background-color: #f47c7c;
}

.bg2 {
  background-color: #ef9f9f;
}

.bg3 {
  background-color: #fad4d4;
}

.bg4 {
  background-color: #fff2f2;
}

/*PRODUCT CAROUSEL*/

.selected-item {
  border: 2px solid #f47c7c;
}

.danger {
  border: solid 2px #c0392b;
  background-color: #e74c3c;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  color: #f1f1f1;
}

.caption-carousel {
  font-size: 9.5px;
  text-align: center;
  margin-bottom: 0 !important;
}

.product-carousel-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.gap {
  line-height: 25px;
}

/*CARDS*/
.hover-shadow:hover {
  box-shadow: 5px 5px 5px #fad4d4bb;
  transform: scale(1.025);
}

.card {
  border: solid 2px #f1f1f1;
  padding: 3px;
}

.product-card-body {
  padding: none !important;
}

.card-img-top {
  height: 220px;
  object-position: center center;
  object-fit: cover;
}

.img-fluid {
  display: flex;
}

.card-link {
  text-decoration: none;
  width: 100%;
}

.card-titre {
  color: #f47c7c;
}
.card-price {
  color: #000;
}

.product-card {
  width: 100%;
}

.product-badge {
  top: 0.5rem;
  right: 0.5rem;
  position: absolute;
}

.card-title-container {
  display: flex;
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

/*NAVBAR*/
.navbar {
  background-color: #f47c7c;
}

.menu-principal a {
  color: #f1f1f1;
  font-weight: 400;
  text-transform: uppercase;
}

.navbar img {
  cursor: pointer;
}

.menu-principal a:hover {
  color: black;
  transition: all ease-out;
  transition-duration: 1s;
}

.footer-link a:hover {
  color: black;
  transition: all ease-out;
  transition-duration: 1s;
}

.navbar2 {
  --bs-nav-link-padding-x: 0.6em;
  --bs-nav-link-padding-y: 3.5px;
  background-color: #ef9f9f;
}

.user-bar {
  background-color: #fff2f2;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

#admin-nav-dropdown {
  color: #f47c7c;
}

#basic-nav-dropdown {
  color: #f47c7c;
}

.user-link {
  color: #f47c7c;
}

.navbar2 .nav-link {
  text-decoration: none;
  color: #fff2f2;
}

.cart-link {
  background-color: #fff2f2;
  padding: 15px 15px;
  border-radius: 100%;
  color: #f47c7c;
  position: relative;
}

.badge-items {
  position: absolute;
  z-index: 5;
  margin-left: 35px;
  background-color: #ef9f9f;
  color: #fff2f2;
  border-radius: 100%;
}

.cart-link:hover {
  background-color: #ef9f9f;
  color: #fff2f2;
  transition: all ease-out;
  transition-duration: 0.5s;
}

.offcanvas {
  max-width: 70%;
}

/*PRIX*/
.price-tag {
  font-size: 20px;
  font-weight: 500;
}

/*CAROUSEL*/

.readMore-btn {
  color: #f47c7c;
  text-decoration: underline;
}

.btn-zoom {
  position: absolute;
  font-size: 1.1em;
  margin-right: 58px;
  background-color: #f47c7c;
  padding: 2px;
  color: #f1f1f1;
}

.slick-slide .hover-shadow.flex-fill.card {
  margin: 12px 12px;
}

.btn-default:hover {
  border: none;
  padding: 0;
  background: none;
  color: #f47c7c;
}

.btn-default {
  border: none;
  padding: 0;
  background: none;
  color: #f47c7c;
}

.btn-carousel-left-container {
  height: 100%;
  position: relative;
}

.btn-carousel-right-container {
  height: 100%;
  position: relative;
}

.btn-carousel-left {
  font-size: 35px;
  color: #f47c7c;
  position: absolute;
  z-index: 3;
  margin-top: 120px;
  margin-left: -30px;
}

.btn-carousel-right {
  font-size: 35px;
  color: #f47c7c;
  position: absolute;
  z-index: 3;
  margin-top: 120px;
  margin-left: -5px;
}

.carousel-item img {
  height: 400px;
  object-fit: cover;
}

.carousel-home {
  margin-top: -34px;
}

.carousel-caption {
  background: rgba(255, 242, 242, 0.5);
  color: #000;
  border-radius: 30px;
}

.title {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ETOILES NOTES*/
.rating span {
  color: #000;
}

/*BADGE EN STOCK*/
.badge-stock {
  background-color: #ef9f9f;
  font-size: 12px;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

/*BADGE EPUISE*/
.badge-epuise {
  background-color: red;
  font-size: 12px;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

/*FORMULAIRES*/

.small-container {
  max-width: 800px;
}

/*CHECKOUT STEPS*/

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #000;
}

.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f47c7c;
  color: #f08000;
}

.card {
  background-color: #f1f1f1;
  border-color: lightgray;
}
.card a {
  text-decoration: none;
  color: #f47c7c;
}

.card-rating {
  font-size: 90%;
}
.fontaw-check {
  font-size: 1.3rem;
}

/*
LOGO
*/

.btrr-lg {
  border-top-right-radius: 140px;
}
.btlr-lg {
  border-top-left-radius: 140px;
}
.bbr-lg {
  border-bottom-left-radius: 135px;
  border-bottom-right-radius: 135px;
}

/*RESPONSIVE*/

@media screen and (max-width: 991px) {
  .scroll {
    max-height: 100%;
    overflow-y: visible;
  }

  .product-vignettes {
    display: none;
  }

  .carousel-item {
    max-height: 400px;
  }

  .carousel-item img {
    height: 300px;
    object-fit: cover;
    opacity: 0.7;
  }
  .logo-footer {
    display: none;
  }
  .logo-mobile {
    width: 100px;
  }
  #admin-nav-dropdown {
    font-size: 14px;
  }

  #basic-nav-dropdown {
    font-size: 14px;
  }

  .mobile-cart {
    margin-left: -45px;
  }
  .site-cart {
    display: none;
  }
}

.cat-link:hover,
.sub-cat-link:hover {
  background-color: #fad4d4;
  color: #f47c7c;
}

@media screen and (max-width: 575px) {
  .btn-carousel-left {
    display: none;
  }
  .btn-carousel-right {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .slick-track {
    display: flex !important;
    align-items: stretch;
  }

  .slick-slide {
    height: auto !important;
    float: unset !important;
    display: flex !important;
    align-items: stretch;
  }

  .slick-slide > div {
    display: flex !important;
    align-items: stretch !important;
    width: 100%;
  }

  .slick-slide > div > div {
    display: flex !important;
    justify-content: center;
  }
}

@media screen and (min-width: 991px) {
  .mobile-cart {
    display: none;
  }
  .categories-menu {
    display: block;
  }
}

@media screen and (max-width: 350px) {
  .signup-button {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .card-titre {
    text-align: center;
  }
  .card-rating {
    text-align: center;
    align-self: center;
  }

  .img-product-container .img-product {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-product-container .product-page-main-image {
    object-fit: scale-down;
    height: 400px;
    width: 100%;
  }
}

@media (orientation: landscape) {
  .is-sticky {
    top: 115px;
  }
}

/* .sticky-searchbar-container {
  min-height: 70px;
} */
